:root {
    --color1: #FAFAFA;
    --color2: #F1F1F1;
    --color3: #379EFF;
    --color4: #1E65FF;
    --color5: #232C33;  
    --color6: #FF8C42;
    --color7: #379EFF24;
    --color8: #0000FF;
    --color9: #FFFFFF;
    --color10: #232C33;
}
html[data-theme='light'] {
    --color1: #FAFAFA;
    --color2: #F1F1F1;
    --color3: #379EFF;
    --color4: #1E65FF;
    --color5: #232C33;  
    --color6: #FF8C42;
    --color7: #379EFF24;
    --color8: #0000FF;
    --color9: #FFFFFF;
    --color10: #232C33;
}
html[data-theme='dark'] {
    --color1: #232C33;
    --color2: #464f57;
    --color3: #bd3a3a;
    --color4: #c40101;
    --color5: #FAFAFA;  
    --color6: #fac000;
    --color7: #379effb7;
    --color8: #7e0000;
    --color9: #FFFFFF;
    --color10: #232C33;
}
@font-face {
    font-family: "interTight";
    src: url("./assets/font/InterTight.ttf");
}
@font-face {
    font-family: "oswald";
    src: url("./assets/font/Oswald.ttf");
}
@font-face {
    font-family: "fairProsper";
    src: url("./assets/font/FairProsper.ttf");
}
@font-face {
    font-family: "raleway";
    src: url("./assets/font/Raleway-Light.ttf");
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;    
}
html {
    scroll-behavior: smooth;
}
body {
    background: var(--color2);
    color: var(--color5);
    font-family:'interTight';
    overflow-x: hidden;
    text-align: center;
}
a {
    text-decoration: none;
}
/*================================================================ SCROLLBAR =====================================================================*/
::-webkit-scrollbar {
    width: 8px;    
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--color1); 
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color3); 
    border-radius: 5px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color4); 
  }
 /*============================================================== FIN SCROLLBAR =====================================================================================*/ 
 .bouton {
    border: solid 1px var(--color6);
    border-radius: 3px;
    color: var(--color6);
    background: transparent;
    padding: 5px 10px;
    font-size: 16px;
    position: relative;
    transition: all .5s;
    z-index: 10;
}
.bouton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    border-radius: 3px;
    background: var(--color6);
    transition: all .5s;
    z-index: -1;
}
.bouton:hover {
    color: var(--color2);
}
.bouton:hover::before {
    width: 100%;
}
.derouleSite {
    display: flex!important;
    flex-direction: column;
    align-items: center;
    animation: derouleAnim .4s linear both;
}
@keyframes derouleAnim {
    to {
        height: 100%;
    }
}