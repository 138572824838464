#presentationSuite {
    position: relative;
    z-index: 12;
}
#presentationSuite::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--color2);    
    animation: couperEnTravers .4s linear 1.2s forwards;
}
@keyframes couperEnTravers {
    
    99% {
        opacity: 1;
        transform: translateX(100%);              
    }
    100% {
        opacity: 0;
    }
}
.skew-c{
    width:100%;
    height:100px;
    left:0px;
    background: linear-gradient(to left bottom, var(--color2) 49%, var(--color4) 50%);
}
.portfolio-block {
    background:var(--color4);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
}
.portfolio-block h2 {
    color:#fff;
    text-align:center;
    font-size:60px;
    font-family:'oswald';
    font-weight:normal;
    margin: 30px 0;
    opacity: 0;
    animation: remonter2eTitre 1s cubic-bezier(.24,1.46,.74,1.59) 1.4s forwards;
}
@keyframes remonter2eTitre {
    to {
        transform: translateY(-20px);
        opacity: 1;
    }
}
.logoSkill{
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 1000;    
    transition: .5s;    
}
.logoSkill:hover {
    transform: scale(2) !important;
}
.logoContainer {
    width: 50px;
    opacity: 0;
    transition: .5s;
}
#htmlLogoContainer {    
    animation: afficherCompetence .3s linear 2.6s forwards;
}
#phpLogoContainer {
    animation: afficherCompetence .3s linear 3.1s forwards;
}
#symfonyLogoContainer {
    animation: afficherCompetence .3s linear 3.6s forwards;
}
#wordpressLogoContainer {
    animation: afficherCompetence .3s linear 4.1s forwards;
}
#javaLogoContainer {    
    animation: afficherCompetence .3s linear 4.6s forwards;
}
/*
#javaLogo {
    cursor: default;
}*/
#reactLogoContainer {
    animation: afficherCompetence .3s linear 5.1s forwards;
}
@keyframes afficherCompetence {
    to {        
        opacity: 1;
    }
}
.skillsLogos{
    display: flex;
    justify-content: center;
    gap: 30px;
    background: var(--color1);
    border-radius: 3px;
    overflow: hidden;
    padding: 20px 50px;
    /*opacity: 0;
    animation: remonterParagraphe 1s cubic-bezier(.24,1.46,.74,1.59) 1.6s forwards;*/
}
.circlingSkillsLogos {
    border-radius: 3px;
    padding: 3px;
    opacity: 0;
    position: relative;
    overflow: hidden;
    background: #fff;
    animation: remonterParagraphe 1s cubic-bezier(.24,1.46,.74,1.59) 1.6s forwards;
}
.circlingSkillsLogos::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    background: linear-gradient(145deg, var(--color9) 5%, var(--color8), var(--color9), var(--color8), var(--color9), 
                                        var(--color8), var(--color9), var(--color8), var(--color9));
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: animateLogos 10s linear infinite;  
} 
@keyframes animateLogos {
    0% {
        background-position: 100% 0;
    }    
    50% {
        background-position: 0 0;
    }
    100% {
        background-position: -100% 0;
    }
}
.portfolioP {
    text-align: left;
    color:#fff;
    width: 40%;
    font-size:20px;
    font-family:'interTight';
    opacity: 0;
    margin-bottom: 30px;
    animation: remonterParagraphe 1s cubic-bezier(.24,1.46,.74,1.59) 1.6s forwards;
}
@keyframes remonterParagraphe {
    to {
        transform: translateY(-20px);
        opacity: 1;
    }
}

.portfolioLine {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 60px;
}
.portfolioLine .reseauxLogoBas {
    width: 20px;
    height: 20px;
    display: block;
    margin-bottom: 5px;
}
#githubLogo {
    width: 22px;
    height: 22px;    
}
.reseauxLogosBasGauche {
    position: relative;
    top: -30px;
}
.scrollLien {
    position: relative;
    top: -30px;
    animation: scrollAnim 1.6s linear 1.6s infinite both;
}
@keyframes scrollAnim {
    from {
        opacity: 0;
        transform: translateY(-100%);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.scroll {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    position: relative;
    color: #232C33;
}
.scrollLien::after {
    content: "";
    position: absolute;
    height: 32px;
    width: 2px;
    right: 10px;
    top: 44px;
    background: var(--color5);    
}
.portfolio {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 4vw;
    padding: 80px 8vw;
    border-radius: 3px;
    opacity: 0;
    background-color: var(--color2);
    animation: apparaitrePortfolio .5s linear 1.8s forwards;
}
@keyframes apparaitrePortfolio {
    to {
        opacity: 1;
    }
}
.boutonsVideo {
    display: flex;
    gap: 5px;
}
.skew-c2{
    width:100%;
    height:100px;
    left:0px;
    background: linear-gradient(to right bottom, var(--color4) 49%, var(--color2) 50%);
}

#scrollToLogos {
    width: 40px;
    height: 40px;
    position: fixed;
    right: 62px;
    top: 100px;
    box-shadow: 1px 1px 10px var(--color5);
    cursor: pointer;
}

@media screen and (max-width: 1300px) {
    .portfolio {        
        padding: 80px 0;
    }
}
@media screen and (max-width: 1050px) {
    .portfolio-block {
        padding: 3% 20% 10% 20%;
    }
    .portfolio-block h2 {
        line-height: 70px;
    }
    .portfolio {
        grid-template-columns: 1fr;
        padding: 80px 8vw;
    }
    .portfolioP {
        width: 100%;        
    }
    .portfolioLine {
        padding: 0;
    } 
    .skew-c, .skew-c2 {
        height: 50px;        
    }
}
@media screen and (max-width: 670px) {
    .portfolio-block {
        padding-top: 10%;
    }
    .portfolio {
        padding: 10px;
        gap: 8vw;
    } 
    .skillsLogos {
        padding: 20px;
        flex-wrap: wrap;
    }
    #scrollToLogos {
        width: 20px;
        height: 20px;
        position: fixed;
        right: 4px;
        top: 150px;
        box-shadow: 1px 1px 10px var(--color5);
        cursor: pointer;
    }
}