#contact {
    opacity: 0;
    background-color: var(--color4);
    animation: apparaitreContact .5s linear 2.3s forwards;
}
@keyframes apparaitreContact {
    to {
        opacity: 1;
    }
}
.contactTitre {
    font-size:60px;
    font-family:'oswald';
    font-weight:normal;
    margin: 30px 0 30px 0;
}
.contactContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    margin: 100px auto;
    width: 50%;
}
.formContact {
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: center;
    min-width: 300px;
}
.formContact input,
.formContact textarea {
    color: var(--color5);
}

/*================================================================================================================================================*/

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 40%;
    align-self: flex-start;
    min-width: 240px;
}

.contact__option {
    background: var(--color1);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid var(--color4);
    color: var(--color5);
    transition: .5s;
}

.contact__option:hover {
    background: var(--color7);
    border-color: var(--color1);
}

.contact__option-icon {
    font-size: 1.5rem;
}
#contact h3 {
    font-size: 25px;
}
#contact h4 {
    font-weight: 500;
    color: var(--color3);
    font-size: 25px;
    padding-bottom: 10px;
}
.contactMe {
    color: var(--color1);
}
/* ===============FORM==========================*/
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: var(--color1);
    border: 2px solid var(--color4);
    resize: none;
    font-size: 16px;
}

@media screen and (max-width: 1300px) {
    
    .contactContainer {
        width: 70%;
    }
}

@media screen and (max-width: 670px) {    
    .contactContainer {
        width: 100%;
        flex-wrap: wrap;
        margin: 50px auto;
        gap: 30px;
    }
}