
.portfolio .item > img:nth-of-type(1) {
    width: 500px;
    height: 300px;
    border-radius: 3px;
}

.item {
    position: relative;
    width: 500px;
    height: 300px;
    box-shadow: 1px 1px 10px var(--color5);
    border-radius: 3px;
    transition: all 1s;
}
.item-overlay {
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    background: #232C33DD;
    color: var(--color9);
    overflow: hidden;
    text-align: center;
    width: 100%;
    transition: top 0.8s;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.item:hover .item-overlay {
    top: 0;
}
.portfolio .item h3 {
    font-size: 30px;
    margin-bottom: 30px;
}
.portfolio .item p {
    font-size:20px;
    text-align: left;
    margin: 0 20px 30px 20px;
}
.boutonVoir {
    background: transparent;
    width: 80px;
    border: solid 1px var(--color6);
    border-radius: 3px;
    color: var(--color6);
    padding: 5px 10px;
    transition: all .5s;
    position: relative;
    z-index: 10;
}
.boutonVoir::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    height: 100%;
    width: 0%;
    background: var(--color6);    
    transition: all .5s;
    z-index: -1;
}
.boutonVoir:hover {
    color: var(--color2);
}
.boutonVoir:hover::before {
    width: 100%;
}

.petiteCroix {
    width: 40px;
    height: auto;
    position: fixed;
    right: 8%;
    top: 10%;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
    border-radius: 10px;
    box-shadow: 0 0 5px var(--color2);
}
#overlay1,
#overlay2,
#overlay3,
#overlay4
 {
    position: fixed;
    display: none;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .9);
    z-index: 2000;
    overflow-y: scroll;
    transition: all .4s;
}
#airGoRunBigScreen,
#contactPageBigScreen,
#blogAPIBigScreen,
#rcMortagneBigScreen
{
    width: 80%;
}
.derouleSite {
    display: flex!important;
    flex-direction: column;
    align-items: center;
    animation: derouleAnim .4s linear both;
}
@keyframes derouleAnim {
    to {
        height: 100%;
    }
}
.centrerGrandeImage {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 670px) {
    
    .portfolio .item > img:nth-of-type(1) {
        width: 300px;
        height: 180px;
        border-radius: 2px;
    }
    .item {
        width: 300px;
        height: 180px;
        border-radius: 2px;
    }   
    
    .portfolio .item h3 {
        font-size: 18px;
        margin-bottom: 2px;
    }
    .portfolio .item p {
        font-size:14px;
        text-align: left;
        margin-bottom: 8px;
        margin-left: 10px;
    }
    
}