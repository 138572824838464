.logo{
    font-family: "fairProsper";    
    font-size: 20px;
    align-self: center;
    color: var(--color4);
}
.nomTitreConteneur {
    background-color: var(--color1);
    min-height: 190px;
    padding-top: 10px;
}
.logoThemeButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.colorSwitch {
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    visibility: hidden;
}
.colorSwitchLabel {
    width: 40px;
    height: 20px;
    position: relative;
    display: block;
    background: var(--color1);
    border-radius: 20px;
    box-shadow: inset 0px 5px 15px rgba(0,0,0,0.4), inset 0px -5px 15px rgba(255,255,255,0.4);
    cursor: pointer;
    transition: .5s;
    opacity: 0;
    animation: apparaitreBoutonCV .5s linear 1.8s forwards;
}
.colorSwitchLabel::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, var(--color3), var(--color4));
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
    transition: .5s;
}
.colorSwitch:checked + .colorSwitchLabel {
    background: #404040;
}
.colorSwitch:checked + .colorSwitchLabel::after {
    left:40px;
    transform: translateX(-100%);
    background: linear-gradient(180deg, var(--color3), var(--color4));
}
.colorSwitchLabel:active::after {
    width: 30px;
}
.reseauxLogo {
    width: 20px;
    height: 20px;
    display: block;
    margin-bottom: 5px;
}
.nomReseaux {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 60px;
}
.reseauxLogosHaut {
    align-self: center;
    z-index: 10;
}
h1 {
    font-size:80px!important;
    font-family:'oswald';
    font-weight:normal;
    margin-bottom: 1rem;    
    opacity: 0;
    background: linear-gradient(var(--color3),var(--color4));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: descendreTitres .5s ease-out forwards;
}
.tilt {
    width: auto!important;
    padding: 0 30px 30px!important;
    transform-style: preserve-3d;
}
.tilt div {
    transform: translateZ(30px);
}
.montee h2 {
    font-size:30px;
    font-family:'Raleway', serif;
    font-weight: 600;
    padding: 1rem;
    margin-bottom: 0;
    opacity: 0;
    animation: descendreTitres .5s ease-out .2s forwards;
}
@keyframes descendreTitres {
    
    to {
        transform: translateY(20px);
        opacity: 1;
    }
}
.montee {
    position: relative;
}
.montee::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--color2);    
    animation: coupeMontee .4s linear .5s forwards;
}
@keyframes coupeMontee {
    
    99% {
        opacity: 1;
        transform: translateX(100%);              
    }
    100% {
        opacity: 0;
    }
}
.skew-cc{
    width:100%;
    height:100px;
    left:0px;
    background: linear-gradient(to left top, var(--color2) 49%, var(--color1) 50%);
}
.photoParcoursConteneur {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 250px;
    flex-wrap: wrap;
    padding-bottom: 30px;
}
.portraitConteneur {    
    position: relative;
    border-radius: 10px;
}
.portrait {
    width: 250px;
    border-radius: 10px;
}
.voletPortrait::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--color2);    
    animation: decouvrirPortrait .5s linear .2s forwards;
}
@keyframes decouvrirPortrait {
    
    99% {
        opacity: 1;
        transform: translateX(100%);              
    }
    100% {
        opacity: 0;
    }
}
.mot {
    text-align: left;
    width: 420px;
    display: flex;
    align-items: center;
    background-color: var(--color1);
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    justify-content: center;
}
.mot .quoteOpen {
    width: 40px;
    height: auto;
    align-self: flex-start;
}
.mot .quoteClose {
    width: 40px;
    height: auto;
    align-self: flex-end;
}

.mot p {
    font-size: 20px;
    line-height: 30px;
    padding: 0 20px 0 40px;
    margin: 0;
}
#boutonCV {
    border: solid 1px var(--color6);
    border-radius: 3px;
    color: var(--color6);
    padding: 5px 10px;
    transition: all .5s;
    z-index: 10;
    opacity: 0;
    position: relative;
    animation: apparaitreBoutonCV .5s linear 1.8s forwards;
}
@keyframes apparaitreBoutonCV {
    to {
        opacity: 1;
    }
}
#boutonCV::before {
    content: "";
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0%;
    background-color: var(--color6);    
    transition: all .5s;
    z-index: -1;
}
#boutonCV:hover {
    color: var(--color1);
}
#boutonCV:hover::before {
    width: 100%;
}
@media screen and (max-width: 1050px) {
    .photoParcoursConteneur {
        gap: 0;
    }   
    .skew-cc{
        height: 50px;        
    }
    .nomTitreConteneur {
        padding-top: 20px;
    }
    .mot {
        margin: 20px 20px 0 20px;
    }
    h1 {
        line-height: 100px;
    }
    .reseauxLogo,
    .reseauxLogosBasGauche,
    .scrollLien,
    .logo {
        display: none;
    }
    .nomReseaux {
        justify-content: center;
        padding: 0; 
    }
}