#fullCalendar{
    margin: 0 10%;
    opacity: 0;
    animation: apparaitreCalendar .5s linear 1.8s forwards;
}
@keyframes apparaitreCalendar {
    to {
        opacity: 1;
    }
}
.calendarTitre {
    font-size:60px;
    font-family:'oswald';
    font-weight:normal;
    margin: 0 0 30px 0;
}

h2.fc-toolbar-title {
    color: var(--color4);
}
.modal-title, .modal label, .modal-body {
    color: var(--color10);
}
.fc-button-active {
    background-color: var(--color4) !important;
    border-color: var(--color4) !important;
}
.fc-button-primary {
    background-color: var(--color3) !important;
    border-color: var(--color3) !important;
}
.fc .fc-timegrid .fc-daygrid-body {
    /*display: none;*/
}
.fc thead tr {
    background: var(--color1);
}

@media only screen and (max-width: 916px) {
    #fullCalendar {
        margin: 0;
    }
}
@media only screen and (max-width: 700px) {
    
    .fc-toolbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5em;
    }
    .fc-left {
        order: 3;
    }
}